import { UserData } from './../../models/auth/user-data.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit,OnDestroy {

  email = "";
  password = "";
  _hidePassword: boolean = true;
  isRemembered = false;
  failLoginAttempt = 0;
  reamianingWaitingTime = "";
  loginDisabled: boolean = true;
  WAITING_INTERVAL = 1; //minutes

  constructor(
    private apiService: ApiService,
    private router: Router,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    if(this.dataService.checkApiToken()) {
      this.router.navigate(['/account'])
    }
  }

  togglePassword() {
    this._hidePassword = !this._hidePassword;
  }

  ngOnDestroy(): void {
    this.dataService.hideSpinner();
  }

  login() {
    if(this.failLoginAttempt == 6) {
      return;
    }

    this.dataService.showSpinner();
    let userDataPayload = {
      "email": this.email,
      "password": this.password
    }


    this.apiService.wsPost(userDataPayload,this.apiService.login).then((data:any) => {
      if(data) {
        if(data.success && data.message.token != null && data.message.token != undefined) {
          this.dataService._apiToken = data.message.token;
          // if(this.isRemembered) {
            localStorage.setItem("apitoken",JSON.stringify(data.message.token));
            let userDataResponse = new UserData();
            userDataResponse.email = this.email;
            userDataResponse.user_type = data.message.user_type;
            userDataResponse.id = data.message.id;
            userDataResponse.name = data.message.name;
            userDataResponse.logo = data.logo;

            this.dataService.setUserData(userDataResponse);
            localStorage.setItem("userData",JSON.stringify(userDataResponse));
          // }

          this.dataService.hideSpinner();
          // this.dataService.openSnackBar(this.dataService._message_list.SuccessLogin,"Close","success-snackbar");
          this.router.navigate(['']);
        }else {
          this.dataService.showSpinner();
          this.dataService.openSnackBar(data.message,"Close","danger-snackbar");
        }
      }else {
        this.dataService.hideSpinner();
        this.failLoginAttempt = this.failLoginAttempt + 1;
        this.dataService.openSnackBar(this.dataService._message_list.FailLogin,"Close","danger-snackbar");

        if(this.failLoginAttempt == 3) {
          this.startWaitingTimer(this.WAITING_INTERVAL);
        }

        if(this.failLoginAttempt == 6) {
          this.dataService.openSnackBar(this.dataService._message_list.AccountLocked,"Close","danger-snackbar");
        }
      }
    }).catch(error => {
      this.dataService.hideSpinner();
      if(error.error.message != undefined) {
        let errorMsg = "";
        if(Array.isArray(error.error.message)) {
          errorMsg = error.error.message[0];
        }else {
          errorMsg = error.error.message;
        }
        this.dataService.openSnackBar(errorMsg,"Close","danger-snackbar");
      }else {
        this.dataService.openSnackBar(this.dataService._message_list.FailLogin1,"Close","danger-snackbar");
      }
    })
  }

  startWaitingTimer(minutes:number) {
    let time = minutes * 60;
    this.loginDisabled = true;
    var timer = setInterval(() => {
      if(time == 0) {
        this.loginDisabled = false;
        this.reamianingWaitingTime = "";
        clearInterval(timer);
      }else if(time > 0){
        time--;
        let displayMinutes = this.transform(time);
        this.reamianingWaitingTime = displayMinutes + " minutes";
      }
    }, 1000);
  }

  resolved(event: any) {
    this.loginDisabled = false;
  }

  transform(value: number, args?: any): string {

    const hours: number = Math.floor(value / 60);
    const minutes: number = (value - hours * 60);

    if (hours < 10 && minutes < 10) {
        return '0' + hours + ' : 0' + (value - hours * 60);
    }
    if (hours > 10 && minutes > 10) {
        return '0' + hours + ' : ' + (value - hours * 60);
    }
    if (hours > 10 && minutes < 10) {
        return hours + ' : 0' + (value - hours * 60);
    }
    if (minutes > 10) {
        return '0' + hours + ' : ' + (value - hours * 60);
    }
    if (minutes == 10) {
      return '0' + hours + ' : ' + (value - hours * 60);
    }
  }

}
