import { DataService } from 'src/app/services/data.service';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LocationGuard  {

  constructor(
    private router: Router,
    private dataService: DataService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isValidActivateUrl(route, state);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.isValidActivateUrl(route, state);
  }

  private isValidActivateUrl(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // return true;
    if(this.dataService.checkApiToken()) {
        return true;
    }else {
      this.router.navigate(['login']);
      return false;
    }
  }
}
