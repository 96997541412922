<div class="main-wrapper">
    <div class="page-wrapper full-page">
        <div class="page-content d-flex align-items-center justify-content-center">
            <div class="row w-100 mx-0 auth-page">
                <div class="col-md-6 col-xl-4 mx-auto">
                    <div class="card">
                        <div class="row">
                            <div class="col-md-12">
                            <div class="auth-form-wrapper px-4 py-5">
                                <form class="forms-sample">
                                <div class="mb-3">
                                    <label for="userEmail" class="form-label">Email address</label>
                                    <input type="email"
                                    [(ngModel)]="email"
                                    [ngModelOptions]="{standalone: true}"
                                    class="form-control"
                                    id="userEmail" placeholder="Email">
                                </div>
                                <div class="mb-3">
                                    <label for="userPassword" class="form-label">Password</label>
                                    <div class="input-group">
                                        <input type="password"
                                        [(ngModel)]="password"
                                        [ngModelOptions]="{standalone: true}"
                                        class="form-control"
                                        id="userPassword"
                                        autocomplete="current-password"
                                        placeholder="Password"
                                        [type]="_hidePassword ? 'password' : 'text'" >
                                        <div class="input-group-append">
                                            <a style="height: 100%;" class="input-group-text" *ngIf="_hidePassword" (click)="togglePassword()">
                                                <i appFeatherIcon data-feather="eye"></i>
                                            </a>
                                            <a style="height: 100%;" class="input-group-text" *ngIf="!_hidePassword" (click)="togglePassword()">
                                                <i appFeatherIcon data-feather="eye-off"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="form-check mb-3">
                                    <div class="row">
                                        <div class="col-8">
                                            <input type="checkbox" [(ngModel)]="isRemembered" [ngModelOptions]="{standalone: true}" class="form-check-input" id="authCheck">
                                            <label class="form-check-label" for="authCheck">
                                                Remember me
                                            </label>
                                        </div>
                                        <div class="col-4">
                                            {{reamianingWaitingTime}}
                                        </div>
                                    </div>
                                </div> -->
                                <!-- <div class="row mt-2 mb-2">
                                    <re-captcha (resolved)="resolved($event)"
                                        siteKey="6LfVXpUdAAAAAE__w80h2kWtDBwJAKXyWNeKaZvy">
                                    </re-captcha>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <button (click)="login()"

                                        class="btn btn-primary me-2 mb-2 mb-md-0 text-white">
                                        Login
                                        </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
