import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FeahterIconModule } from './core/feather-icon/feather-icon.module';
import {
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ResourceManagementComponent } from './pages/resource-management/resource-management.component';
import { VearthSettingsComponent } from './pages/settings/vearth-settings/vearth-settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResourceManagementComponent,
    VearthSettingsComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    FeahterIconModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
  ],
  providers: [provideHttpClient(withInterceptorsFromDi())],
})
export class AppModule {}
