import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { DataService } from './services/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'vearth-admin';

  constructor(private http: HttpClient,
    public dataService: DataService
  ) { }

  ngOnInit(): void {
    this.http.get('./assets/json/message.json?random=' + Math.random())
      .subscribe(data => {
        this.dataService._message_list = data;
      });
  }
}
